import React, { type FC } from "react";
import { Logo } from "../../atoms/Logo";
import { SectionBox } from "../../atoms/SectionBox";

export const Header: FC = () => {
  return (
    <header
      data-cy="header"
      className="govuk-header "
      data-module="govuk-header"
    >
      <div className="govuk-header__container govuk-width-container">
        <SectionBox className="header-content">
          <div className="govuk-header__logo">
            <a
              href="/"
              className="govuk-header__link govuk-header__link--homepage logo__link"
            >
              <span className="govuk-header__logotype">
                <Logo
                  width="100%"
                  id="ncsc_logo_header"
                  fill="#fff"
                  className="ncsc-logo"
                  ariaTitle="National Cyber Security Center - Check Your Cyber Security Homepage"
                />
                <Logo
                  width="100%"
                  id="ncsc_logo_black_header"
                  fill="#000"
                  className="ncsc-logo-black"
                  ariaTitle="National Cyber Security Center - Check Your Cyber Security Homepage"
                />
              </span>
            </a>
          </div>
          <div className="govuk-header__content">
            <a
              href="https://ncsc.gov.uk"
              id="service_link_desktop"
              className="desktop govuk-header__link govuk-header__link--service-name govuk-link--style2"
              target="_blank"
              rel="noreferrer"
            >
              go to ncsc.gov.uk
            </a>
            <a
              href="https://ncsc.gov.uk"
              className="mobile govuk-header__link govuk-header__link--service-name govuk-link--style2"
              target="_blank"
              rel="noreferrer"
            >
              ncsc.gov.uk
            </a>
          </div>
        </SectionBox>
      </div>
    </header>
  );
};

export default Header;
